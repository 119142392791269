import { AwsRum } from 'aws-rum-web';

const initAwsRum = () => {
  let rum;

  try {
    const {
      REACT_APP_COGNITO_RUM_GUEST_ROLE_ARN,
      REACT_APP_COGNITO_RUM_IDENTIY_POOL_ID,
      REACT_APP_COGNITO_RUM_APPLICATION_ID,
      REACT_APP_COGNITO_RUM_REGION,
    } = process.env;

    const config = {
      sessionSampleRate: 1,
      guestRoleArn: REACT_APP_COGNITO_RUM_GUEST_ROLE_ARN,
      identityPoolId: REACT_APP_COGNITO_RUM_IDENTIY_POOL_ID,
      endpoint: 'https://dataplane.rum.eu-west-1.amazonaws.com',
      telemetries: ['performance', 'errors'],
      allowCookies: true,
      enableXRay: false,
      disableAutoPageView: true,
    };

    const APPLICATION_ID = REACT_APP_COGNITO_RUM_APPLICATION_ID;
    const APPLICATION_VERSION = '1.0.0';
    const APPLICATION_REGION = REACT_APP_COGNITO_RUM_REGION;

    rum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config,
    );
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
  return rum;
};

const shouldLog = () => {
  if (process.env.CONNECT_ENV === 'development') return false;
  if (window.location.host === 'wikiinline.scania.com') return false;
  if (window.location.pathname.startsWith('/edit')) return false;

  return true;
};

const awsRum = () => {
  if (shouldLog()) {
    const rum = initAwsRum();
    let { pathname } = window.location;

    if (pathname.startsWith('/profile')) {
      // removes everything after /profile
      pathname = '/profile';
    }

    rum.recordPageView(pathname);
  }
};

export default awsRum;
